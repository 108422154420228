body{
    div{
        main{
            #profile{
                section{
                    padding:0;
                    margin:0;
                    form{
                        justify-content: space-between;
                        margin:10px;
                        padding:10px;
                        align-items: center;
                    }
                }
                div{
                    justify-content: space-between;
                    flex-wrap: wrap;
                }
                h1{
                    color:var(--background);
                    background-color: var(--optBG);
                }
            }
        }
    }
}