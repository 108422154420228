@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&display=swap');

body::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

//THEME-COLOR_SETS
.BW {
    --background: rgb(240, 240, 240);
    --darkmain: rgb(100, 100, 100);
    --darksecondary: rgb(50, 50, 50);
    --brightthirdly: rgb(230, 230, 230);
    --brightmain: var(--background);
    --brightmainOpacity: rgba(240, 240, 240, .4);
    --brightsecondary: var(--background);
    --optBG: rgba(50, 50, 50, 0.7);
}

.blue {
    background-image: url("../components/amillionfriends.jpg");
    --background: rgb(250, 250, 255);
    --darkmain: rgb(80, 80, 150);
    --darksecondary: rgb(100, 100, 150);
    --brightthirdly: rgb(240, 255, 240);
    --brightmain: var(--background);
    --brightmainOpacity: rgba(250, 250, 255, .4);
    --brightsecondary: var(--background);
    --optBG: rgba(100, 100, 150, 0.7);
}

.green {
    background-image: url("../components/bg3.jpg");
    --background: rgb(250, 255, 250);
    --darkmain: rgb(50, 100, 0);
    --darksecondary: rgb(30, 80, 0);
    --brightthirdly: rgb(240, 255, 240);
    --brightmain: var(--background);
    --brightmainOpacity: rgba(250, 255, 250, .4);
    --brightsecondary: var(--background);
    --optBG: rgba(50, 100, 0, 0);
}

.red {
    background-image: url("../components/bg2.jpg");
    --background: rgba(255, 252, 245, .9);
    --darkmain: rgb(190, 51, 36);
    --darksecondary: rgb(140, 21, 6);
    --brightthirdly: rgb(255, 235, 205);
    --brightmain: var(--background);
    --brightmainOpacity: rgba(255, 252, 245, .4);
    --brightsecondary: var(--background);
    --optBG: rgba(190, 51, 36, 0);
}

//Structured Styling
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Barlow', sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
    background-color: var(--background);
    background-attachment: fixed;
    background-size: cover;

    >div {
        background-size: cover;
        background-attachment: fixed;
        min-height: 100vh;
    }

    header {
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 100;
        background-color: var(--darkmain);
        box-shadow: 0 0 10px var(--darksecondary);

        &:hover {
            background-color: var(--darksecondary);
            transition: all 0.2s ease 0s;
        }

        nav {
            max-width: 1200px;
            width: 95%;
            height: 50px;
            margin: 0 auto;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .active {
                border-bottom: solid 2px var(--brightthirdly);
                // box-shadow: 0px 0px 2px var(--darksecondary) inset;
                // transform: scale(1.2);
                // text-decoration: underline;
                // color:black
            }
                        
            a {
                padding: .3em 1em .1em 1em;
                border-radius: 5px;
                color: var(--brightthirdly);
                text-decoration: none;

                &:hover {
                    background-color: var(--darkmain);
                    box-shadow: 0px 0px 5px var(--darksecondary);
                    transform: translateY(3px);
                    // box-shadow: 0 0 0 5px var(--brightthirdly);
                    // color: var(--darkmain);
                    // background-color: var(--brightthirdly);
                    transition: all 0.2s ease 0s;
                    transform: scale(1.1);
                }
            }

            svg {
                color: var(--brightthirdly);

                &:hover {
                    color: rgb(50, 50, 50);
                }
            }
        }
    }

    main {
        height: 90%;

        article {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;

            >form {
                position: sticky;
                top: 50px;
                // width: 100%;
                background-color: var(--brightmainOpacity);
                border-radius: 5px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                padding: 10px;
                box-shadow: 0px 10px 13px -7px #000000, 6px 5px 15px 2px rgba(0, 0, 0, 0);
            }

            section {
                background-color: var(--brightsecondary);
                box-shadow: 0px 10px 13px -7px #000000, 6px 5px 15px 2px rgba(0, 0, 0, 0);
                border-radius: 5px;
                margin-bottom: 20px;
                padding: 20px;

                >div {
                    .profileText {
                        border: 2px var(--darkmain) solid;
                        background-color: var(--brightsecondary);
                        font-style: italic;
                        font-size: 1.4rem;
                        width: fit-content;
                        padding: 10px;
                        margin-top: 10px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

// Styling over whole Document
button {
    height: 2.5rem;
    width: 4em;
    padding: .3em 1em .1em 1em;
    font-size: 1.4rem;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease 0s;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: var(--darkmain);
        box-shadow: 0px 4px 20px var(--darksecondary);
        color: #fff;
        transform: translateY(-3px);
    }

    &:active {
        transform: translateY(-1px);
    }
}

.activeFriend {
    color: var(--darkmain);

    &:hover {
        color: white;
    }
}

#deleteUser {
    width: fit-content;
    background-color: red;
}

.error{
    box-shadow: 0 0 10px red inset;
}

input {
    height: 2.5rem;
    font-size: 1.4rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 90;
    border: 0;
    border-radius: 5px;
    // border-right: 1px solid;
    display: flex;
    text-align: left;
}

::placeholder {
    padding-left: 10px;
}

select {
    height: 2.5rem;
    font-size: 1.5rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 90;
    border: 0;
    padding: 0 30px;
    border-radius: 5px;
    // border-right: 1px solid;
    background-color: white;
    color: rgb(117, 117, 117);
    text-align: left;
}

textarea {
    font-size: 1.4rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 200;
    width: 100%;
    margin-top: 10px;
}

img {
    border-radius: 5px;
}

h1{
    border-radius: 5px;
}

#fileSelector {
    width: 100%;
}

.maxW {
    width: 90%;
}

.fullW {
    width: 350px;
}

.midW {
    width: 150px;
}

.smallW {
    width: 50px;
    ;
}

.selectInSearch {
    height: 70%;
    font-size: 1.5rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 90;
    text-align: center;
    padding: 0 20px 10px 20px;
}

.show {
    display: flex;
    flex-wrap: wrap;
}

.hide {
    display: none;
}

.flex {
    display: flex;
}

.left {
    .profileText {
        background-color: var(--darkmain);
        color: var(--brightthirdly);
    }
}

.right {
    justify-content: end;
}

#logo871 {
    width: 26px;
    height: 26px;
}

#henriksLoadingAnimation {
    animation: henriksAnimation 3s infinite;
    margin: 0 auto;
    display: block;
}

@keyframes henriksAnimation {
    0% {
        transform: rotate(0deg)
    }

    50% {
        width: 150px;
    }

    100% {
        transform: rotate(360deg)
    }
}

//On Small Screens
@media only screen and (max-width:740px) {
    .bigScreen {
        display: none;
    }

    .messageHeader {
        display: block;
    }

    img {
        width: 100%;
    }

    .maxW {
        width: 80%;
    }

    .fullW {
        width: 100%;
    }

    .midW {
        width: 100px;
    }

    .smallW {
        width: 50px;

    }
}

.newMessage{
    animation: massageNewAnimation 1s infinite;
}

@keyframes massageNewAnimation{
    0% { color: white}
    50% {  color: var(--darkmain) }
    100% { color: white}
}

//On Big Screens
@media only screen and (min-width:740px) {
    .smallScreen {
        display: none;
    }

    img {
        width: 200px;
    }

    .css-1s2u09g-control {
        height: 2.5rem;
        border: 0;

        .css-1s2u09g-control {
            border: 0;
        }

        .css-1hwfws3 {
            margin: 0;
            padding: 0;
        }

        .css-g1d714-ValueContainer {
            margin: 0;
            padding: 0;
        }

        .css-b62m3t-container {
            height: 2.5rem;
        }

        .css-1pahdxg-control {
            height: 2.5rem;
        }

        .css-1rhbuit-multiValue {
            margin: 0;
            padding: 0;
        }
    }

}

// Very small size:
@media only screen and (max-width:350px) {
    body{
        font-size: 1rem;
    header{
        nav{
            padding:0;
            margin:0;
        a{
            padding:0;
            margin:0; 
        }
}}}} 