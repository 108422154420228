
body{
    div{
        main{
            #forum{
                >form{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    input{
                        width: 100px;
                        height: 36px;
                        margin-bottom: 2px;
                    }
                    select{
                        width: 100px;
                        height: 38px;
                        padding: 0;
                        margin-bottom: 2px;
                    }
                    .selectInSearch{
                        // min-width: 100px;
                        padding: 0;
                        display: flex;
                        .css-319lph-ValueContainer{
                            padding: 0%;
                            .css-6j8wv5-Input{
                                padding: 0%;
                                margin: 0;
                            }
                        }
                    }
                    button {
                        background-image: url(../components/icons8-search.gif);
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 100px;
                        height: 38px;
                        box-shadow: none;
                        background-size:2rem;
                        z-index: 1;
                    }
                    button:hover {
                        background-color: white;
                        border: none;
                        box-shadow: none;
                        transform: none;
                        transform: scale(1.1);
                    }
                }
                #messagesSearch{
                    .ProfileCard{
                        display: grid;
                        .imgSearch{
                            grid-area: img;
                        }
                        .searchDivUserName{
                            grid-area: name;
                            padding-left: 5px;
                            align-self: center;
                            font-weight: 900;
                        }
                        .distance{
                            grid-area: distance;
                            padding-left: 5px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }
                        .gender{
                            grid-area: gender;
                            font-size: 3.1rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .age{
                            grid-area: age;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .btn1{
                            grid-area: btn1;
                            justify-self: center;
                            align-self: center;
                        }
                        .btn2{
                            grid-area: btn2;
                            justify-self: center;
                            align-self: center;
                        }
                        .profileText{
                            border: none;
                            grid-area: profT;
                            padding-left: 5px;
                            border-top: 2px solid var(--darkmain);
                            margin-top: 3px;
                            display: inline-block;

                        }
                        form{
                            width: 100%;
                            grid-area: form;
                            input{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:740px){
    .ProfileCard{
        // display: grid;
        grid-template-columns:  repeat(9, 1fr);
        grid-template-areas: 
        "img img name name distance gender age btn1 btn2" 
        "img img profT profT profT profT profT profT profT" 
        "form form form form form form form form form";
    }
}
@media only screen and (max-width:740px){
        .ProfileCard{
            display: grid;
            grid-template-columns: 150px repeat(2, 1fr);
            grid-template-areas: 
            "img img img"
            "img img img"
            "name gender age"
            "distance btn1 btn2"
            "profT profT profT"
            "form form form";
        }
    }
    
    @media only screen and (max-width:350px){
        .ProfileCard{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 
            "img img"
            "img img"
            "name distance"
            "gender age"
            "btn1 btn2"
            "profT profT"
            "form form";
        }
    }